import "./Contact.scss";
import React, {Component} from 'react';
import {Trans} from "react-i18next";

export default class Contact extends Component {
    render() {
        return (
            <div className="contact-section">
                <h1>
                    <Trans i18nKey="contact.title">Always ready for the next adventure</Trans>
                    <span>🦙</span>
                </h1>
                <div className="contact-container">
                    <a className="contact-item" href="https://github.com/wobkenh" target="_blank" rel="noreferrer">
                        <div className="icon github"/>
                        <div>wobkenh</div>
                    </a>
                    <a className="contact-item" onClick={() => this.openMail("evonetic")} style={{cursor: 'pointer'}}>
                        <div className="icon email"/>
                        <div>
                            <Trans i18nKey="contact.business">Business</Trans>
                            <br/>
                            henning.wobken [at] evonetic.de
                        </div>
                    </a>
                    <a className="contact-item" onClick={() => this.openMail("simplex24")} style={{cursor: 'pointer'}}>
                        <div className="icon email"/>
                        <div>
                            <Trans i18nKey="contact.private">Private</Trans>
                            <br/>
                            henning.wobken [at] simplex24.de
                        </div>
                    </a>
                    <a className="contact-item" href="https://open.spotify.com/user/1149975981" target="_blank"
                       rel="noreferrer">
                        <div className="icon spotify"/>
                        <div>Henning Wobken</div>
                    </a>
                    <a className="contact-item" href="https://steamcommunity.com/id/eXHoppopotamus/" target="_blank"
                       rel="noreferrer">
                        <div className="icon steam"/>
                        <div>Henning</div>
                    </a>
                    <a className="contact-item"
                       href="https://www.snapchat.com/add/henning.wobken?share_id=slHqIGTZOl0&locale=de-DE"
                       target="_blank" rel="noreferrer">
                        <div className="icon snapchat"/>
                        <div>henning.wobken</div>
                    </a>
                    <a className="contact-item" href="https://www.instagram.com/henningwobken/" target="_blank"
                       rel="noreferrer">
                        <div className="icon instagram"/>
                        <div>henningwobken</div>
                    </a>
                </div>
                <div className="fontawesome-notice">
                    <a href="https://fontawesome.com/" target="_blank" rel="noreferrer">
                        Icons from FontAwesome licensed under Creative Commons Attribution 4.0 International license
                    </a>
                </div>
            </div>
        );
    }

    openMail(domain) {
        const mail = "henning.wobken" + "@" + domain + ".de";
        window.open("mailto:" + mail);
    }
}
