import "./Footer.scss";
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div>
                    <Trans i18nKey="footer.handbuilt">Handbuilt with ♥ and ✨</Trans>
                </div>
                <div>
                    © 2024 Henning Wobken. All rights reserved.
                </div>
                <div>
                    <Link to="imprint">
                        <Trans i18nKey="footer.imprint">Imprint</Trans>
                    </Link>
                    <Link to="privacy-policy">
                        <Trans i18nKey="footer.privacy">Privacy Policy</Trans>
                    </Link>
                </div>

            </div>
        );
    }
}
