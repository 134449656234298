import "./Navbar.scss";
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import i18n from "i18next";
import { Trans } from "react-i18next";

export default class Navbar extends Component {

    constructor() {
        super();
        if (/(iP(hone|od|ad)|Mac)/.test(navigator.userAgent)) {
            document.body.classList.add("apple");
        }

        // please note,
        // that IE11 now returns undefined again for window.chrome
        // and new Opera 30 outputs true for window.chrome
        // but needs to check if window.opr is not undefined
        // and new IE Edge outputs to true now for window.chrome
        // and if not iOS Chrome check
        // so use the below updated condition
        const isChromium = window.chrome;
        const winNav = window.navigator;
        const vendorName = winNav.vendor;
        const isOpera = typeof window.opr !== "undefined";
        const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
        const isIOSChrome = winNav.userAgent.match("CriOS");

        if (isIOSChrome) {
            // is Google Chrome on IOS
        } else if (
            isChromium !== null &&
            typeof isChromium !== "undefined" &&
            vendorName === "Google Inc." &&
            isOpera === false &&
            isIEedge === false
        ) {
            document.body.classList.add("chrome");
        }
    }

    switchLanguage() {
        i18n.changeLanguage(i18n.language.startsWith("en") ? "de" : "en").then(() => {
            this.props.rerenderParentCallback();
        });
    }

    render() {
        return (
            <div className="navbar">
                <div>
                    <div>
                        <NavLink to="/" className="orange">
                            Henning Wobken
                        </NavLink>
                    </div>
                    <div>
                        <NavLink to="/portfolio" className="purple">
                            Portfolio
                        </NavLink>
                    </div>
                    <div>
                        <NavLink to="/contact" className="pink">
                            <Trans i18nKey="navbar.contact">Contact</Trans>
                        </NavLink>
                    </div>
                </div>
                <a className="language-switcher"
                   onClick={() => this.switchLanguage()}>
                    {i18n.language.startsWith("de") ? '🇺🇸' : '🇩🇪' }
                </a>
            </div>
        );
    }
}
