import "./PrivacyPolicy.scss";
import { Component } from "react";


export default class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="imprint">
                <div className="imprint-container">
                    <h2>1. Datenschutz auf einen Blick</h2>


                    <h3>Allgemeine Hinweise</h3>


                    <p>Die
                        folgenden Hinweise geben einen einfachen Überblick darüber, was mit
                        Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
                        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
                        identifiziert werden können. Ausführliche Informationen zum Thema
                        Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
                        Datenschutzerklärung.</p>


                    <h3>Datenerfassung auf dieser
                        Website</h3>


                    <p><strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong></p>


                    <p>Die
                        Datenverarbeitung auf dieser Website erfolgt durch den
                        Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
                        Website entnehmen.</p>


                    <p><strong>Wie erfassen wir Ihre Daten?</strong></p>


                    <p>Ihre
                        Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
                        Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein
                        Kontaktformular eingeben.</p>


                    <p>Andere Daten werden automatisch
                        beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
                        allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder
                        Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
                        automatisch, sobald Sie diese Website betreten.</p>


                    <p><strong>Wofür nutzen wir Ihre Daten?</strong></p>


                    <p>Ein
                        Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
                        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
                        Nutzerverhaltens verwendet werden.</p>


                    <p><strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong></p>


                    <p>Sie
                        haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
                        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
                        erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
                        dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
                        Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen
                        Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei
                        der zuständigen Aufsichtsbehörde zu.</p>


                    <p>Außerdem haben Sie
                        das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung
                        Ihrer personenbezogenen Daten zu verlangen. Details hierzu entnehmen
                        Sie der Datenschutzerklärung unter „Recht auf Einschränkung der
                        Verarbeitung“.</p>


                    <h2>2. Allgemeine
                        Hinweise und Pflichtinformationen</h2>


                    <h3>Datenschutz</h3>


                    <p>Die
                        Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr
                        ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
                        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
                        Datenschutzerklärung.</p>


                    <p>Wenn Sie diese Website benutzen,
                        werden verschiedene personenbezogene Daten erhoben. Personenbezogene
                        Daten sind Daten, mit denen Sie persönlich identifiziert werden können.
                        Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
                        und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck
                        das geschieht.</p>


                    <p>Wir weisen darauf hin, dass die
                        Datenübertragung im Internet (z.&nbsp;B. bei der Kommunikation per E-Mail)
                        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor
                        dem Zugriff durch Dritte ist nicht möglich.</p>


                    <h3>Hinweis zur verantwortlichen
                        Stelle</h3>


                    <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>


                    <p>Henning Wobken<br/>Margaretha-Meinders-Straße links 18<br/>26871 Papenburg</p>


                    <table>
                        <tbody>
                        <tr>
                            <td style={{paddingBottom: "8px"}}>E-Mail (privat):</td>
                            <td>henning.wobken [at] simplex24.de</td>
                        </tr>
                        <tr>
                            <td style={{paddingRight: '15px'}}>E-Mail (geschäftlich):</td>
                            <td>henning.wobken [at] evonetic.de</td>
                        </tr>
                        </tbody>
                    </table>


                    <p>Verantwortliche
                        Stelle ist die natürliche oder juristische Person, die allein oder
                        gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
                        personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. Ä.)
                        entscheidet.</p>


                    <h3>Widerruf Ihrer
                        Einwilligung zur Datenverarbeitung</h3>


                    <p>Viele
                        Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
                        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
                        jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
                        uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                        Datenverarbeitung bleibt vom Widerruf unberührt.</p>


                    <h3>Widerspruchsrecht
                        gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</h3>


                    <p>WENN
                        DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
                        DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
                        IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
                        PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
                        AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
                        RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
                        DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
                        IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI
                        DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
                        NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER
                        DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG
                        VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</p>


                    <p>WERDEN
                        IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
                        BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
                        VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
                        DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT
                        ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
                        WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
                        MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
                        ABS. 2 DSGVO).</p>


                    <h3>Beschwerderecht
                        bei der zuständigen Aufsichtsbehörde</h3>


                    <p>Im
                        Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
                        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
                        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
                        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
                        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
                        Rechtsbehelfe.</p>


                    <h3>Recht auf
                        Datenübertragbarkeit</h3>


                    <p>Sie
                        haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder
                        in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an
                        einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu
                        lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
                        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
                        machbar ist.</p>


                    <h3>SSL- bzw. TLS-Verschlüsselung</h3>


                    <p>Diese
                        Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
                        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
                        Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
                        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
                        dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt
                        und an dem Schloss-Symbol in Ihrer Browserzeile.</p>


                    <p>Wenn die
                        SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie
                        an uns übermitteln, nicht von Dritten mitgelesen werden.</p>


                    <h3>Auskunft, Löschung und Berichtigung</h3>


                    <p>Sie
                        haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das
                        Recht auf unentgeltliche Auskunft über Ihre gespeicherten
                        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
                        Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
                        dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
                        Daten können Sie sich jederzeit unter der im Impressum angegebenen
                        Adresse an uns wenden.</p>


                    <h3>Recht auf Einschränkung der Verarbeitung</h3>


                    <p>Sie
                        haben das Recht, die Einschränkung der Verarbeitung Ihrer
                        personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
                        unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf
                        Einschränkung der Verarbeitung besteht in folgenden Fällen:</p>


                    <ul>
                        <li>Wenn
                            Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten
                            bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für
                            die Dauer der Prüfung haben Sie das Recht, die Einschränkung der
                            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                        </li>
                        <li>Wenn
                            die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                            geschah/geschieht, können Sie statt der Löschung die Einschränkung der
                            Datenverarbeitung verlangen.
                        </li>
                        <li>Wenn wir Ihre personenbezogenen
                            Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung
                            oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht,
                            statt der Löschung die Einschränkung der Verarbeitung Ihrer
                            personenbezogenen Daten zu verlangen.
                        </li>
                        <li>Wenn Sie einen Widerspruch
                            nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
                            Ihren und unseren Interessen vorgenommen werden. Solange noch nicht
                            feststeht, wessen Interessen überwiegen, haben Sie das Recht, die
                            Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                            verlangen.
                        </li>
                    </ul>


                    <p>Wenn Sie die Verarbeitung Ihrer
                        personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
                        ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur
                        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum
                        Schutz der Rechte einer anderen natürlichen oder juristischen Person
                        oder aus Gründen eines wichtigen öffentlichen Interesses der
                        Europäischen Union oder eines Mitgliedstaats verarbeitet werden.</p>


                    <h2>3. Datenerfassung auf dieser
                        Website</h2>


                    <h3>Cookies</h3>


                    <p>Die
                        Internetseiten verwenden teilweise so genannte Cookies. Cookies richten
                        auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies
                        dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer
                        zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner
                        abgelegt werden und die Ihr Browser speichert.</p>


                    <p>Die
                        meisten der von uns verwendeten Cookies sind so genannte
                        „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
                        gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie
                        diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim
                        nächsten Besuch wiederzuerkennen.</p>


                    <p>Sie können Ihren
                        Browser so einstellen, dass Sie über das Setzen von Cookies informiert
                        werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies
                        für bestimmte Fälle oder generell ausschließen sowie das automatische
                        Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der
                        Deaktivierung von Cookies kann die Funktionalität dieser Website
                        eingeschränkt sein.</p>


                    <p>Cookies, die zur Durchführung des
                        elektronischen Kommunikationsvorgangs oder zur Bereitstellung
                        bestimmter, von Ihnen erwünschter Funktionen (z.&nbsp;B. Warenkorbfunktion)
                        erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
                        gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der
                        Speicherung von Cookies zur technisch fehlerfreien und optimierten
                        Bereitstellung seiner Dienste. Sofern eine entsprechende Einwilligung
                        abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies),
                        erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1
                        lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</p>


                    <p>Soweit
                        andere Cookies (z.&nbsp;B. Cookies zur Analyse Ihres Surfverhaltens)
                        gespeichert werden, werden diese in dieser Datenschutzerklärung
                        gesondert behandelt.</p>


                    <h3>Server-Log-Dateien</h3>


                    <p>Der
                        Provider der Seiten erhebt und speichert automatisch Informationen in
                        so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
                        übermittelt. Dies sind:</p>


                    <ul>
                        <li>Browsertyp und Browserversion</li>
                        <li>verwendetes Betriebssystem</li>
                        <li>Referrer URL</li>
                        <li>Hostname des zugreifenden Rechners</li>
                        <li>Uhrzeit der Serveranfrage</li>
                        <li>IP-Adresse</li>
                    </ul>


                    <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>


                    <p>Die
                        Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
                        DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
                        technisch fehlerfreien Darstellung und der Optimierung seiner Website –
                        hierzu müssen die Server-Log-Files erfasst werden.</p>


                    {/*<h2>4. Plugins und Tools</h2>*/}


                    {/*<h3>Google Web Fonts</h3>*/}


                    {/*<p>Diese*/}
                    {/*    Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte*/}
                    {/*    Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite*/}
                    {/*    lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um*/}
                    {/*    Texte und Schriftarten korrekt anzuzeigen.</p>*/}


                    {/*<p>Zu diesem*/}
                    {/*    Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern*/}
                    {/*    von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass*/}
                    {/*    über Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung von*/}
                    {/*    Google Web Fonts erfolgt im Interesse einer einheitlichen und*/}
                    {/*    ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein*/}
                    {/*    berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.</p>*/}


                    {/*<p>Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer*/}
                    {/*    genutzt.</p>*/}


                    {/*<p>Weitere Informationen zu Google Web Fonts finden Sie unter&nbsp;<a*/}
                    {/*    href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a>&nbsp;und*/}
                    {/*    in der Datenschutzerklärung von Google:&nbsp;<a*/}
                    {/*        href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.*/}
                    {/*</p>*/}

                    <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
                </div>
            </div>
        );
    }
}
